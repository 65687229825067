import React, { useState, useContext } from "react";
import { GlobalDataContext } from "../../context/context";
// import Form from "../Contact/Form";

const Modal = () => {
  const [showModal, setShowModal] = useState(true);
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      {showModal ? (
        <>
          <div
            className="relative z-50"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
          >
            <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity"></div>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <div className="relative transform overflow-hidden rounded-lg bg-blue-100 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className=" bg-blue-100 pt-5">
                    <div className="sm:flex sm:items-start">
                      <button
                        className="absolute top-3 right-3 px-2 rounded-full text-black hover:text-red-600"
                        id="modal-title"
                        onClick={() => setShowModal(false)}
                      >
                        <i className="fa fa-times"></i>
                      </button>
                      <div className="w-full flex justify-center item-center flex-col ">
                        <div className="w-full flex justify-center ">
                          <img
                            src={rpdata?.dbPrincipal?.logo}
                            className="md:w-[50%] w-[70%]"
                            alt="Not Found"
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <h4 className="text-center">Request An Estimate Now</h4>
                    <Form /> */}
                  <div class="bg-blue-100 p-6 rounded-lg shadow-lg text-center mt-1">
                    <h1 class="text-2xl md:text-4xl font-bold text-red-600 mb-4">Attention!</h1>
                    <p class="text-lg md:text-xl font-semibold text-gray-800 mb-4 capitalize">
                      We only attend via text message and WhatsApp.
                    </p>
                    <div class="bg-yellow-200 p-4 rounded-md shadow-md">
                      <h2 class="text-xl md:text-2xl font-bold text-yellow-800 mb-2">
                        Estimates are made only by the owner of the business:
                      </h2>
                      <p class="text-2xl md:text-3xl font-bold text-yellow-900">
                        Mrs. Maria Casiano
                      </p>
                      <p class="text-lg md:text-xl font-semibold text-yellow-900">
                        I personally handle all estimates, ensuring it is not done by anyone else representing me.
                      </p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default Modal;
